@font-face {
  font-family: "ALSHauss-Black";
  src: url("../assets/fonts/ALSHauss-Black.woff2");
}

@font-face {
  font-family: "ALSHauss-BlackItalic";
  src: url("../assets/fonts/ALSHauss-BlackItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Bold";
  src: url("../assets/fonts/ALSHauss-Bold.woff2");
}
@font-face {
  font-family: "ALSHauss-BoldItalic";
  src: url("../assets/fonts/ALSHauss-BoldItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Book";
  src: url("../assets/fonts/ALSHauss-Book.woff2");
}
@font-face {
  font-family: "ALSHauss-BookItalic";
  src: url("../assets/fonts/ALSHauss-BookItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Hairline";
  src: url("../assets/fonts/ALSHauss-Hairline.woff2");
}
@font-face {
  font-family: "ALSHauss-HairlineItalic";
  src: url("../assets/fonts/ALSHauss-HairlineItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Light";
  src: url("../assets/fonts/ALSHauss-Light.woff2");
}
@font-face {
  font-family: "ALSHauss-LightItalic";
  src: url("../assets/fonts/ALSHauss-LightItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Medium";
  src: url("../assets/fonts/ALSHauss-Medium.woff2");
}
@font-face {
  font-family: "ALSHauss-MediumItalic";
  src: url("../assets/fonts/ALSHauss-MediumItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Regular";
  src: url("../assets/fonts/ALSHauss-Regular.woff2");
}
@font-face {
  font-family: "ALSHauss-RegularItalic";
  src: url("../assets/fonts/ALSHauss-RegularItalic.woff2");
}
@font-face {
  font-family: "ALSHauss-Thin";
  src: url("../assets/fonts/ALSHauss-Thin.woff2");
}
@font-face {
  font-family: "ALSHauss-ThinItalic";
  src: url("../assets/fonts/ALSHauss-ThinItalic.woff2");
}

body {
  font-family: "ALSHauss-Regular", sans-serif;
}
